import "./index.css";
import React, { useEffect, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DashboardCounter from "../../components/DashboardCounter/index";
import Select from "react-select";

import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import PageLayout from "../../layouts/PageLayout";
import Ico1 from "../../assets/img/edit.png";
import Ico2 from "../../assets/img/delete.png";
import Ico3 from "../../assets/img/s.png";
import Ico4 from "../../assets/img/d.png";
import Clap from "../../assets/img/clap.png";
import Diconetwo from "../../assets/img/real_time.svg";
import Diconethree from "../../assets/img/active_daily_mgmt.svg";
import Iconone from "../../assets/img/beds.png";
import Icontwo from "../../assets/img/compliance.png";
import Iconthree from "../../assets/img/variance.png";
import Iconfour from "../../assets/img/hpuoc.png";
import Ico22 from "../../assets/img/info.png";
import Ico33 from "../../assets/img/warning.png";
import Ico44 from "../../assets/img/critical.png";
import More from "../../assets/img/ui.png";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { get, isEmpty } from "lodash";
import { API_CALL_URL_PRIVATE, API_CALL_URL_PUBLIC } from "../../constants";
import axios from "../../axios";
import { DOMAIN_URL } from "../../constants/compensate";
import ReactPaginate from "react-paginate";
import { setLoadingPage } from "../../store/actions";
import { Switch } from "antd";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import ReactDatePicker from "react-datepicker";
import { toDate } from "@amcharts/amcharts5/.internal/core/util/Type";
// import { useSelector } from "react-redux";
import { CiCalendarDate } from "react-icons/ci";
import { flushSync } from "react-dom";
import AscIcon from "../../assets/img/desc.png";
import DescIcon from "../../assets/img/asc.png";
import DetailModal from "../../components/DetailModal";
import MultiSelect from "../../components/MultiSelectCustom/MultiSelectActivity";
import { ThemeContext } from "../../providers/ThemeProvider";

function UserActivity(props) {
  let lastDate = null;
  const [theme, setTheme] = useContext(ThemeContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("psAdmin"));
  const customer_code = useSelector((state) => state.compensate.customerCode);
  const loading_page = useSelector((state) => state.compensate.LoadingPage);
  console.log(loading_page, "loading");
  const [dashboardData, setDashboardData] = useState([]);
  const [customerData, setCustomerData] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [pageLimit, setPageLimit] = useState(50);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [hasPrev, setHasPrev] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [sortKey, setSortKey] = useState("");
  const [isInitialData, setISInitialData] = useState(false);
  const [sortOrder, setSortOrder] = useState(-1);
  const boxdata = props.data;
  const isLoader = props.isLoader;
  const { issearch } = props;
  const [userDatas, setUserDatas] = useState(get(userData, "data", []));
  const [pageCounter, setPageCounter] = useState("");
  const [searchTempKeyword, setSearchTempKeyword] = useState("");
  const [isStatusDrop, setIsStatusDrop] = useState(false);
  const [statusUpdate, setStatusUpdate] = useState(false);
  const [isCommentDelete, setIsCommentDelete] = useState(false);
  const [costCenterMaster, setCostCenterMaster] = useState(null);
  const [listLoader, setListLoader] = useState(false);
  const [showModal, setShowModal] = useState({
    state: false,
    id: null,
  });

  const [dropCostCenterMaster, setDropCostCenterMaster] = useState([]);
  console.log(dropCostCenterMaster);
  const [applicationMaster, setApplicationMaster] = useState(null);
  const [dropApplicationMaster, setDropApplicationMaster] = useState([]);
  const [employeeMaster, setEmployeeMaster] = useState(null);
  const [dropEmployeeMaster, setDropEmployeeMaster] = useState([
    { value: "", label: "All" },
  ]);
  const [activityMaster, setActivityMaster] = useState(null);
  const [dropActivityMaster, setDropActivityMaster] = useState([]);
  const [dropTimeDuration, setDropTimeDuration] = useState([
    { value: "day", label: "Day" },
    { value: "week", label: "Week" },
    { value: "month", label: "Month" },
  ]);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [applicationName, setApplicationName] = useState([]);
  const [costCenterName, setCostCenterName] = useState([]);
  const [employeeName, setEmployeeName] = useState("");
  const [activityName, setActivityName] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [dropUserType, setDropUserType] = useState([]);
  const [userTypeSelected, setUserTypeSelected] = useState([]);
  const [userType, setUserType] = useState([]);
  const [expandedComments, setExpandedComments] = useState({});
  const getDate = (e) => {
    const inputDate = new Date(e);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0");
    const day = String(inputDate.getDate()).padStart(2, "0");
    return `${month}/${day}/${year}`;
  };

  function formatDateComp(inputDate) {
    const timeZone = "America/Los_Angeles";
    return new Date(inputDate).toLocaleDateString("en-US", {
      timeZone: timeZone,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }


    const getActivityDetailList = async () => {
      try {
        const resp = await axios.get(
          `${API_CALL_URL_PRIVATE}/comment?customer_code=${encodeURIComponent(
            customer_code
          )}&searchKeyword=${encodeURIComponent(
            searchKeyword
          )}&sort_column=${encodeURIComponent(
            searchActive ? "" : sortKey
          )}&sort_order=${encodeURIComponent(
            searchActive ? -1 : sortOrder
          )}&page=${encodeURIComponent(
            searchActive ? 1 : currentPage
          )}&limit=${encodeURIComponent(pageLimit)}`
        );

        const Data = resp?.data?.data?.data;

        console.log(Data);
        setSearchActive(false);
        setCustomerData(Data);
        setPageLimit(Data.limit ? Data.limit : 50);
        setTotalPages(Data?.totalPages);
        setCurrentPage(Data.page ? Data.page : 1);
        setHasNext(Data?.hasNextPage);
        setHasPrev(Data?.hasPrevPage);
        setPageCounter(Data?.pagingCounter);
        setListLoader(false);
        setISInitialData(true);
        setIsCommentDelete(false);
        dispatch(setLoadingPage(false));
      } catch (error) {
        console.log(error);
      }
    };

  useEffect(() => {
    getActivityDetailList();
  }, [
    searchKeyword,
    pageLimit,
    sortKey,
    currentPage,
    sortOrder,
    customer_code,
    statusUpdate,
    applicationName,
    userType,
    costCenterName,
    activityName,
    dateFrom,
    dateTo,
    userTypeSelected,
  ]);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
    setListLoader(true);
  };

  function formatDate(inputDate) {
    const timeZone = "America/Los_Angeles";
    const date = new Date(inputDate).toLocaleString("en-US", {
      timeZone,
      hour12: true,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });

    return date;
  }

  const renderSortIcon = (key) => {
    if (sortKey !== key) return <img src={DescIcon} alt="Descending" />;
    return sortOrder === -1 ? (
      <img src={DescIcon} alt="Descending" />
    ) : sortOrder === 1 ? (
      <img src={AscIcon} alt="Ascending" />
    ) : null;
  };
  const handleSort = (key) => {
    if (sortKey === key) {
      setSortOrder(sortOrder === -1 ? 1 : -1);
      setCurrentPage(1);
      setListLoader(true);
    } else {
      setSortKey(key);
      setSortOrder(1);
      setCurrentPage(1);
      setListLoader(true);
    }
  };


  const commentDelete = async (id) => {

    document.body.style.overflow = "hidden";
    Swal.fire({
      title: "Confirmation",
      text: `Are you sure you want to delete the comment`,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // setListLoader(true);
        setIsCommentDelete(true);
        setCustomerData([]);
        try {
          const { data } = await axios.delete(
            `${API_CALL_URL_PRIVATE}/comment/delete/${id}`
          );
          getActivityDetailList();
          // setListLoader(false);
          toast.success("Record Deleted Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        } catch (error) {
          setIsCommentDelete(false);
          toast.error(error?.response?.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        }
      }
    });
  };

   const toggleComment = (index) => {
     setExpandedComments((prevState) => ({
       ...prevState,
       [index]: !prevState[index],
     }));
   };

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h3 className="page-title">
              <a href="#" className="badge badge-dark"></a>
            </h3>
            <div className></div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center mobile-wrap">
                    <h4
                      className="card-title fs-6 mobile-w-100"
                      style={{
                        width: "200px",
                        marginBottom: "0px",
                        marginTop: "15px",
                      }}
                    >
                      Comment Center
                    </h4>
                  </div>
                  <div className="d-flex justify-content-between mt-5 mobile-wrap">
                    <div class="dataTables_length" id="example_length">
                      <label>
                        Show &nbsp;
                        <select
                          name="example_length"
                          aria-controls="example"
                          class=""
                          value={pageLimit}
                          onChange={(e) => {
                            setPageLimit(e.target.value);
                            setListLoader(true);
                          }}
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>{" "}
                        &nbsp;entries
                      </label>
                    </div>
                    <div>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault(); // Prevent the default form submission behavior
                          if (searchKeyword !== searchTempKeyword) {
                            flushSync(() => {
                              setSearchActive(true);
                              setSortKey("");
                            });
                            setListLoader(true);
                            setSearchKeyword(searchTempKeyword);
                          }
                        }}
                      >
                        {/* <div >
                        <button type="button" class="btn btn-success">
                          Add Application
                        </button>
                      </div> */}
                        <div
                          id="example_filter"
                          class="dataTables_filter d-flex align-items-center justify-content-center "
                        >
                          <label>
                            Search: &nbsp;
                            <input
                              type="text"
                              class=""
                              placeholder=""
                              value={searchTempKeyword}
                              aria-controls="example"
                              onChange={(e) => {
                                setSearchTempKeyword(e.target.value);
                                if (e.target.value === "") {
                                  setListLoader(true);
                                  setSearchKeyword("");
                                }
                              }}
                              // onKeyDown={(e) => {
                              //   if (e.key === "Enter") {
                              //     setSearchKeyword(e.target.value);
                              //   }
                              // }}
                            />
                          </label>

                          <div
                            style={{
                              border: "1px solid #aaa",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "9px",
                              marginBottom: "7px",
                              cursor: "pointer",
                              marginTop: "-1px",
                              marginLeft: "-1px",
                            }}
                            onClick={() => {
                              if (searchKeyword !== searchTempKeyword) {
                                flushSync(() => {
                                  setSearchActive(true);
                                  setSortKey("");
                                });
                                setListLoader(true);
                                setSearchKeyword(searchTempKeyword);
                              }
                            }}
                          >
                            <i class="ph ph-magnifying-glass"></i>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div>
                    <div className="table-responsive">
                      <table className="table filter-table">
                        <thead>
                          <tr>
                            <th>No.</th>

                            <th
                              onClick={() => {
                                handleSort("category");
                              }}
                            >
                              Category
                              <span className="ms-1 bright">
                                {renderSortIcon("category")}
                              </span>
                            </th>
                            <th
                              onClick={() => {
                                handleSort("cost_center_name");
                              }}
                            >
                              Cost Center
                              <span className="ms-1 bright">
                                {renderSortIcon("cost_center_name")}
                              </span>
                            </th>
                            <th
                              onClick={() => {
                                handleSort("manager_review");
                              }}
                            >
                              Manager Review
                              <span className="ms-1 bright">
                                {renderSortIcon("manager_review")}
                              </span>
                            </th>

                            <th
                              onClick={() => {
                                handleSort("submitted_by");
                              }}
                            >
                              Submitted By
                              <span className="ms-1 bright">
                                {renderSortIcon("submitted_by")}
                              </span>
                            </th>

                            <th
                              onClick={() => {
                                handleSort("comment");
                              }}
                            >
                              Comment
                              <span className="ms-1 bright">
                                {renderSortIcon("comment")}
                              </span>
                            </th>

                            <th
                              onClick={() => {
                                handleSort("createdAt");
                              }}
                            >
                              Created At
                              <span className="ms-1 bright">
                                {renderSortIcon("createdAt")}
                              </span>
                            </th>

                            <th>Action</th>
                          </tr>
                        </thead>
                        {!isCommentDelete &&
                        isInitialData &&
                        !loading_page &&
                        !listLoader ? (
                          <>
                            {customerData?.docs?.length > 0 ? (
                              <tbody>
                                {customerData.docs.map((Ele, ind) => {
                                  const isExpanded = expandedComments[ind];
                                  const truncatedComment =
                                    Ele?.comment.substring(0, 45);
                                  const displayComment = isExpanded
                                    ? Ele?.comment
                                    : truncatedComment;
                                  return (
                                    <tr key={Ele._id}>
                                      <td>{ind + pageCounter}</td>
                                      <td>{Ele?.category}</td>
                                      <td>
                                        {`${Ele?.cost_center_number} - ${Ele?.cost_center_name}`}
                                      </td>
                                      <td>
                                        {Ele?.manager_review ? "Yes" : "No"}
                                      </td>
                                      <td>{Ele?.submitted_by_name}</td>
                                      <td>
                                        {displayComment}
                                        {Ele?.comment.length > 45 && (
                                          <span
                                            className="shwmore"
                                            onClick={() => toggleComment(ind)}
                                          >
                                            {isExpanded ? (
                                              "Less"
                                            ) : (
                                              <img
                                                src={More}
                                                width={18}
                                                title="Read More"
                                              />
                                            )}
                                          </span>
                                        )}
                                      </td>
                                      <td>{getDate(Ele?.createdAt)}</td>
                                      <td>
                                        <label className="edit">
                                          <MdOutlineDeleteOutline
                                            size={20}
                                            style={{ color: "red" }}
                                            onClick={() => {
                                              commentDelete(Ele?._id);
                                            }}
                                          />
                                        </label>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td colSpan="10">
                                    <div
                                      className="d-flex justify-content-center align-items-center"
                                      style={{ height: "200px" }}
                                    >
                                      <p class="text-secondary display-6">
                                        Oops! Data Not found
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </>
                        ) : (
                          <>
                            <tbody>
                              <tr>
                                <td colSpan="10">
                                  <div
                                    className="d-flex align-items-center justify-content-center w-100"
                                    style={{ height: "400px" }}
                                  >
                                    <span
                                      class="loader"
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                      }}
                                    ></span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </>
                        )}
                      </table>
                    </div>
                    {!isCommentDelete &&
                    isInitialData &&
                    !loading_page &&
                    !listLoader ? (
                      <>
                        {!customerData?.docs?.length > 0 ? (
                          <div className="d-flex justify-content-between">
                            <div
                              class="dataTables_info"
                              id="example_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing 0 to 0 of total 0 entries
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-between">
                            <div
                              class="dataTables_info"
                              id="example_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing {pageCounter} to{" "}
                              {Math.min(
                                pageCounter + pageLimit - 1,
                                customerData.totalDocs
                              )}{" "}
                              of total {customerData?.totalDocs} entries
                            </div>
                            <div
                              class="dataTables_paginate paging_simple_numbers"
                              id="example_paginate"
                            >
                              <span>
                                <ReactPaginate
                                  previousLabel={"Previous"}
                                  nextLabel={"Next"}
                                  breakLabel={"..."}
                                  pageCount={customerData?.totalPages}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={5}
                                  onPageChange={handlePageClick}
                                  containerClassName={"pagination"}
                                  previousLinkClassName={"paginationLink"}
                                  nextLinkClassName={"paginationLink"}
                                  disabledClassName={"paginationDisabled"}
                                  activeClassName={"paginationActive"}
                                  forcePage={currentPage - 1}
                                />
                              </span>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DetailModal
        show={showModal.state}
        id={showModal.id}
        onHide={() => {
          setShowModal({ state: false, id: null });
        }}
      />
    </>
  );
}

export default UserActivity;
