import "./index.css";
import React, { useEffect, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DashboardCounter from "../../components/DashboardCounter/index";
import Select from "react-select";

import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import { RxCrossCircled } from "react-icons/rx";
import Form from "react-bootstrap/Form";
import PageLayout from "../../layouts/PageLayout";
import Ico1 from "../../assets/img/edit.png";
import Ico2 from "../../assets/img/delete.png";
import Ico3 from "../../assets/img/s.png";
import Ico4 from "../../assets/img/d.png";
import Clap from "../../assets/img/clap.png";
import Diconetwo from "../../assets/img/real_time.svg";
import Diconethree from "../../assets/img/active_daily_mgmt.svg";
import Iconone from "../../assets/img/beds.png";
import Icontwo from "../../assets/img/compliance.png";
import Iconthree from "../../assets/img/variance.png";
import Iconfour from "../../assets/img/hpuoc.png";
import Ico22 from "../../assets/img/info.png";
import Ico33 from "../../assets/img/warning.png";
import Ico44 from "../../assets/img/critical.png";
import { get, isEmpty } from "lodash";
import { API_CALL_URL_PRIVATE } from "../../constants";
import { API_CALL_URL_PUBLIC } from "../../constants";
import axios from "../../axios";
import { DOMAIN_URL } from "../../constants/compensate";
import ReactPaginate from "react-paginate";
import { setLoadingPage } from "../../store/actions";
import { Switch } from "antd";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import ReactDatePicker from "react-datepicker";
import { toDate } from "@amcharts/amcharts5/.internal/core/util/Type";
// import { useSelector } from "react-redux";
import { CiCalendarDate } from "react-icons/ci";
import { flushSync } from "react-dom";
import AscIcon from "../../assets/img/desc.png";
import DescIcon from "../../assets/img/asc.png";
import DetailModal from "../../components/AdminActivityDetailModal";
import { LuEye } from "react-icons/lu";
import MultiSelect from "../../components/MultiSelectCustom/MultiSelectActivity";
import { ThemeContext } from "../../providers/ThemeProvider";

function UserActivity(props) {
  let lastDate = null;
  const [theme, setTheme] = useContext(ThemeContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("psAdmin"));
  const customer_code = useSelector((state) => state.compensate.customerCode);
  const loading_page = useSelector((state) => state.compensate.LoadingPage);
  console.log(loading_page, "loading");
  const [dashboardData, setDashboardData] = useState([]);
  const [customerData, setCustomerData] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [pageLimit, setPageLimit] = useState(50);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [hasPrev, setHasPrev] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [sortKey, setSortKey] = useState("");
  const [isInitialData, setISInitialData] = useState(false);
  const [sortOrder, setSortOrder] = useState(-1);
  const boxdata = props.data;
  const isLoader = props.isLoader;
  const { issearch } = props;
  const [userDatas, setUserDatas] = useState(get(userData, "data", []));
  const [pageCounter, setPageCounter] = useState("");
  const [searchTempKeyword, setSearchTempKeyword] = useState("");
  const [isStatusDrop, setIsStatusDrop] = useState(false);
  const [statusUpdate, setStatusUpdate] = useState(false);
  const [costCenterMaster, setCostCenterMaster] = useState(null);
  const [dropCostCenterMaster, setDropCostCenterMaster] = useState([]);
  const [applicationMaster, setApplicationMaster] = useState(null);
  const [dropApplicationMaster, setDropApplicationMaster] = useState([]);
  const [employeeMaster, setEmployeeMaster] = useState(null);
  const [dropEmployeeMaster, setDropEmployeeMaster] = useState([
    { value: "", label: "All" },
  ]);
  const [activityMaster, setActivityMaster] = useState(null);
  const [dropActivityMaster, setDropActivityMaster] = useState([]);
  const [dropTimeDuration, setDropTimeDuration] = useState([
    { value: "day", label: "Day" },
    { value: "week", label: "Week" },
    { value: "month", label: "Month" },
  ]);
  const [dateFrom, setDateFrom] = useState(null);
  const [listLoader, setListLoader] = useState(false);
  const [dateTo, setDateTo] = useState(null);
  const [applicationName, setApplicationName] = useState([]);
  const [costCenterName, setCostCenterName] = useState([]);
  const [employeeName, setEmployeeName] = useState("");
  const [activityName, setActivityName] = useState([]);
  const [isDatePickerFocused, setIsDatePickerFocused] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [userTypeSelected, setUserTypeSelected] = useState([]);
  const [showModal, setShowModal] = useState({
    state: false,
    id: null,
  });

  const getDate = (e) => {
    const inputDate = new Date(e);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0");
    const day = String(inputDate.getDate()).padStart(2, "0");
    return `${month}/${day}/${year}`;
  };
  function formatDateComp(inputDate) {
    const timeZone = "America/Los_Angeles";
    return new Date(inputDate).toLocaleDateString("en-US", {
      timeZone: timeZone,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }

  //   const handleSwitchChange = (status, id) => {
  //     document.body.style.overflow = "hidden";
  //     Swal.fire({
  //       title: "Confirmation",
  //       text: `Are you sure you want to ${
  //         status ? "Active" : "Inactive"
  //       } the status?`,
  //       icon: "info",
  //       showCancelButton: true,
  //       confirmButtonText: "OK",
  //       cancelButtonText: "Cancel",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         statusUpdateApi(status, id);
  //       }
  //     });
  //   };
  //   const statusUpdateApi = async (val, id) => {
  //     try {
  //       const resp = await axios.post(
  //         `${API_CALL_URL_PRIVATE}/portalUser/statusUpdate`,
  //         {
  //           status: parseInt(val),
  //           _id: id,
  //         }
  //       );
  //       console.log(resp);
  //       toast.success(resp?.data.message, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //       });
  //       setStatusUpdate(!statusUpdate);
  //     } catch (error) {
  //       toast.error("Something went wrong..! Try Again", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //       });

  //       console.log(error);
  //     }
  //   };

  const renderElements = () => {
    return Array.from({ length: totalPages }, (_, index) => (
      <span
        key={index + 1}
        className={
          index + 1 === currentPage
            ? "paginate_button current"
            : "paginate_button"
        }
        aria-controls="example"
        role="link"
        aria-current="page"
        data-dt-idx="0"
        tabindex="0"
        onClick={() => {
          setCurrentPage(index + 1);
        }}
      >
        {index + 1}
      </span>
    ));
  };
  const getCostCenter = async () => {
    const resp = await axios.post(
      `${API_CALL_URL_PRIVATE}/department/departmentList`,
      {
        customer_code: parseInt(customer_code),
        limit: "100000",
      }
    );
    setCostCenterMaster(
      resp?.data?.data?.data?.docs?.map((e) => {
        return {
          cost_center_name: e.cost_center_name,
          cost_center_number: e.cost_center_number,
        };
      })
    );
    // setISInitialData(true);
    console.log(resp);
    const DropCost = resp?.data?.data?.data?.docs?.map((e) => {
      return {
        label: e.cost_center_name,
        value: e.cost_center_number,
      };
    });
    setDropCostCenterMaster((prev) => {
      return DropCost?.length > 0
        ? [{ value: "", label: "All" }, ...DropCost]
        : [];
    });
  };
  const getApplicationMaster = async () => {
    const resp = await axios.post(
      `${API_CALL_URL_PRIVATE}/application/applicationList`,
      {
        limit: "100000",
      }
    );
    console.log(resp);
    setApplicationMaster(resp?.data?.data?.data?.docs);
    const DropApp = resp?.data?.data?.data?.docs?.map((e) => {
      return {
        label: e.title,
        value: e.application_id,
      };
    });
    setDropApplicationMaster((prev) => {
      return DropApp?.length > 0
        ? [{ value: "", label: "All" }, ...DropApp]
        : [];
    });
    console.log(resp);
  };
  const getUser = async () => {
    const resp = await axios.post(
      `${API_CALL_URL_PRIVATE}/portalUser/userList`,
      {
        user_type: String("1"),
        customer_code: parseInt(customer_code),
        limit: "100",
      }
    );
    console.log(resp);

    // setCostCenterMaster(
    //   resp?.data?.data?.data?.docs?.map((e) => {
    //     return {
    //       cost_center_name: e.cost_center_name,
    //       cost_center_number: e.cost_center_number,
    //     };
    //   })
    // );
    // // setISInitialData(true);
    // console.log(resp);
    // const DropCost = resp?.data?.data?.data?.docs?.map((e) => {
    //   return {
    //     label: e.cost_center_name,
    //     value: e.cost_center_number,
    //   };
    // });
    // setDropCostCenterMaster((prev) => {
    //   return [...prev, ...DropCost];
    // });
  };
  const getActivityType = async () => {
    const resp = await axios.post(
      `${API_CALL_URL_PRIVATE}/adminActivityMaster/list`
    );
    console.log(resp);

    setActivityMaster(
      resp?.data?.data?.data?.map((e) => {
        return {
          activity_id: e.activity_id,
          title: e.title,
        };
      })
    );
    // // setISInitialData(true);
    // console.log(resp);
    const DropActivity = resp?.data?.data?.data?.map((e) => {
      return {
        label: e.title,
        value: e.activity_id,
      };
    });
    setDropActivityMaster((prev) => {
      return DropActivity?.length > 0
        ? [{ value: "", label: "All" }, ...DropActivity]
        : [];
    });
  };
  useEffect(() => {
    getCostCenter();
    getApplicationMaster();
    // getUser();
    getActivityType();
  }, [customer_code]);

  useEffect(() => {
    const getActivityDetailList = async () => {
      try {
        const resp = await axios.get(
          `${API_CALL_URL_PRIVATE}/adminActivityLog/list?customer_code=${encodeURIComponent(
            customer_code
          )}&application_number=${encodeURIComponent(
            applicationName?.map((e) => e.value).join(",")
          )}&cost_center_number=${encodeURIComponent(
            costCenterName?.map((e) => e.value).join(",")
          )}&activity_id=${encodeURIComponent(
            activityName?.map((e) => e.value).join(",")
          )}&from_date=${
            dateFrom ? encodeURIComponent(getDate(dateFrom)) : ""
          }&to_date=${
            dateTo ? encodeURIComponent(getDate(dateTo)) : ""
          }&searchKeyword=${encodeURIComponent(
            searchKeyword
          )}&sort_column=${encodeURIComponent(
            searchActive ? "" : sortKey
          )}&sort_order=${encodeURIComponent(
            searchActive ? -1 : sortOrder
          )}&limit=${encodeURIComponent(pageLimit)}&page=${encodeURIComponent(
            searchActive ? 1 : currentPage
          )}`
        );
        console.log(resp);

        const Data = resp?.data?.data;
        console.log(Data);
        setSearchActive(false);
        setCustomerData(Data);
        setPageLimit(Data.limit ? Data.limit : 50);
        setTotalPages(Data?.totalPages);
        setCurrentPage(Data.page ? Data.page : 1);
        setHasNext(Data?.hasNextPage);
        setHasPrev(Data?.hasPrevPage);
        setPageCounter(Data?.pagingCounter);
        setListLoader(false);
        setISInitialData(true);
        dispatch(setLoadingPage(false));
      } catch (error) {
        console.log(error);
      }
    };

    getActivityDetailList();
  }, [
    searchKeyword,
    pageLimit,
    currentPage,
    sortOrder,
    customer_code,
    statusUpdate,
    applicationName,
    activityName,
    costCenterName,
    dateFrom,
    dateTo,
    sortKey,
  ]);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
    setListLoader(true);
  };

  // function formatDate(inputDate) {
  //   const timeZone = "America/Los_Angeles";
  //   const date = new Date(
  //     new Date(inputDate).toLocaleString("en-US", { timeZone })
  //   );
  //   const day = String(date.getDate()).padStart(2, "0");
  //   const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  //   const year = date.getFullYear();
  //   const hours = String(date.getHours()).padStart(2, "0"); // 24-hour format
  //   const minutes = String(date.getMinutes()).padStart(2, "0");
  //   return `${month}/${day}/${year}, ${hours}:${minutes}`;
  // }

  function formatDate(inputDate) {
    const timeZone = "America/Los_Angeles";
    const date = new Date(
      new Date(inputDate).toLocaleString("en-US", { timeZone })
    );
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    // Convert to 12-hour format
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? String(hours).padStart(2, "0") : "12"; // the hour '0' should be '12'
    return `${month}/${day}/${year}, ${hours}:${minutes} ${ampm}`;
  }

  const renderSortIcon = (key) => {
    if (sortKey !== key) return <img src={DescIcon} alt="Descending" />;
    return sortOrder === -1 ? (
      <img src={DescIcon} alt="Descending" />
    ) : sortOrder === 1 ? (
      <img src={AscIcon} alt="Ascending" />
    ) : null;
  };
  const handleSort = (key) => {
    if (sortKey === key) {
      setSortOrder(sortOrder === -1 ? 1 : -1);
      setCurrentPage(1);
      setListLoader(true);
    } else {
      setSortKey(key);
      setSortOrder(1);
      setCurrentPage(1);
      setListLoader(true);
    }
  };

  const handleDownloadCsv = async () => {
    const url = `${API_CALL_URL_PUBLIC}/exportCsv/exportConfigUserActivityLogCsv?customer_code=${encodeURIComponent(
      customer_code
    )}&application_number=${encodeURIComponent(
      applicationName?.map((e) => e.value).join(",")
    )}&cost_center_number=${encodeURIComponent(
      costCenterName?.map((e) => e.value).join(",")
    )}&activity_id=${encodeURIComponent(
      activityName?.map((e) => e.value).join(",")
    )}&from_date=${
      dateFrom ? encodeURIComponent(getDate(dateFrom)) : ""
    }&to_date=${
      dateTo ? encodeURIComponent(getDate(dateTo)) : ""
    }&searchKeyword=${encodeURIComponent(searchKeyword)}`;

    window.open(url, "_blank");
  };
  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h3 className="page-title">
              <a href="#" className="badge badge-dark"></a>
            </h3>
            <div className></div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center mobile-wrap">
                    <h4
                      className="card-title fs-6 mobile-w-100"
                      style={{
                        width: "200px",
                        marginBottom: "0px",
                        marginTop: "15px",
                      }}
                    >
                      Admin Activity Detail
                    </h4>
                    <div className="row gx-2 justify-content-between w-100 mobile-half-col">
                      <div className="d-flex flex-column col">
                        <h5 class="m-0 fs-6 ">Application Name</h5>
                        <div
                        //   className="ms-4"
                        >
                          {/* <Select
                            isSearchable="true"
                            options={dropApplicationMaster}
                            className="custom-select"
                            onChange={(e) => {
                              setApplicationName(e.value);
                              setListLoader(true);
                            }}
                          /> */}
                          <MultiSelect
                            key="example_id"
                            placeholder="Select..."
                            options={dropApplicationMaster.filter(
                              (option) => option.value !== ""
                            )}
                            onChange={(e) => {
                              setApplicationName(e);
                              setListLoader(true);
                            }}
                            value={applicationName}
                            isSelectAll={true}
                            menuPlacement={"bottom"}
                            theme={theme.theme === "LIGHT"}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-column col">
                        <h5 class="m-0 fs-6 ">Cost Center Name</h5>
                        <div
                        //   className="ms-4"
                        >
                          {/* <Select
                            isSearchable="true"
                            options={dropCostCenterMaster}
                            className="custom-select"
                            onChange={(e) => {
                              setCostCenterName(e.value);
                              setListLoader(true);
                            }}
                          /> */}

                          <MultiSelect
                            key="example_id"
                            placeholder="Select..."
                            options={dropCostCenterMaster.filter(
                              (option) => option.value !== ""
                            )}
                            onChange={(e) => {
                              setCostCenterName(e);
                              setListLoader(true);
                            }}
                            value={costCenterName}
                            isSelectAll={true}
                            menuPlacement={"bottom"}
                            theme={theme.theme === "LIGHT"}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-column col">
                        <h5 class="m-0 fs-6 ">Activity Type</h5>
                        <div
                        //   className="ms-4"
                        >
                          {/* <Select
                            isSearchable="true"
                            options={dropActivityMaster}
                            className="custom-select"
                            onChange={(e) => {
                              setActivityName(e.value);
                              setListLoader(true);
                            }}
                          /> */}

                          <MultiSelect
                            key="example_id"
                            placeholder="Select..."
                            options={dropActivityMaster.filter(
                              (option) => option.value !== ""
                            )}
                            onChange={(e) => {
                              setActivityName(e);
                              setListLoader(true);
                            }}
                            value={activityName}
                            isSelectAll={true}
                            menuPlacement={"bottom"}
                            theme={theme.theme === "LIGHT"}
                          />
                        </div>
                      </div>
                      {/* <div className="d-flex flex-column col">
                          <h5 class="m-0 fs-6 ">Employee Name</h5>
                          <div
                          //   className="ms-4"
                          >
                            <Select
                              isSearchable="true"
                              options={dropEmployeeMaster}
                              className="custom-select"
                              // onChange={(e) => {
                              //   setCostCenterName(e.value);
                              // }}
                            />
                          </div>
                        </div> */}
                      {/* <div className="d-flex flex-column col">
                          <h5 class="m-0 fs-6 ">Time Duration</h5>
                          <div
                          //   className="ms-4"
                          >
                            <Select
                              isSearchable="true"
                              options={dropTimeDuration}
                              className="custom-select"
                            />
                          </div>
                        </div> */}
                      <div className="d-flex flex-column col dt-pi position-relative">
                        <h5 class="m-0 fs-6 d-flex align-items-center">
                          From Date
                          <CiCalendarDate className="fs-5 mx-2" />
                        </h5>
                        <ReactDatePicker
                          closeOnScroll={(e) => e.target === document}
                          selected={dateFrom}
                          dateFormat="MM/dd/yyyy"
                          isClearable={true}
                          onChange={(date) => {
                            setDateFrom(date);
                            setListLoader(true);
                          }}

                          // onFocus={() => setIsDatePickerFocused(true)}
                          // onBlur={() => setIsDatePickerFocused(false)}
                        />
                        {/* <span
                            style={{
                              position: "absolute",
                              right: "20px",
                              bottom: "8px",
                            }}
                          >
                            {isDatePickerFocused ? (
                              <RxCrossCircled className="fs-5" onClick={()={}} />
                            ) : (
                              <CiCalendarDate className="fs-4" />
                            )}
                          </span> */}
                      </div>
                      <div className="d-flex flex-column col dt-pi">
                        <h5 class="m-0 fs-6 d-flex align-items-center">
                          To Date
                          <CiCalendarDate className="fs-5 mx-2" />
                        </h5>
                        <div
                        //   className="ms-4"
                        >
                          <ReactDatePicker
                            closeOnScroll={(e) => e.target === document}
                            selected={dateTo}
                            dateFormat="MM/dd/yyyy"
                            isClearable={true}
                            minDate={dateFrom || null}
                            onChange={(date) => {
                              setDateTo(date);
                              setListLoader(true);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-5 mobile-wrap">
                    <div class="dataTables_length" id="example_length">
                      <label>
                        Show &nbsp;
                        <select
                          name="example_length"
                          aria-controls="example"
                          class=""
                          value={pageLimit}
                          onChange={(e) => {
                            setPageLimit(e.target.value);
                            setListLoader(true);
                          }}
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>{" "}
                        &nbsp;entries
                      </label>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div>
                        <button
                          style={{ marginRight: "1.2rem" }}
                          type="button"
                          onClick={handleDownloadCsv}
                          disabled={isEmpty(customerData)}
                          className={`btn btn-success ${
                            isEmpty(customerData) ? "not-allowedx" : ""
                          }`}
                        >
                          Download CSV
                        </button>
                      </div>
                      <div>
                        <form
                          onSubmit={(e) => {
                            e.preventDefault(); // Prevent the default form submission behavior
                            if (searchKeyword !== searchTempKeyword) {
                              flushSync(() => {
                                setSearchActive(true);
                                setSortKey("");
                              });
                              setListLoader(true);
                              setSearchKeyword(searchTempKeyword);
                            }
                          }}
                        >
                          <div
                            id="example_filter"
                            class="dataTables_filter d-flex align-items-center justify-content-center "
                          >
                            <label>
                              Search: &nbsp;
                              <input
                                type="text"
                                class=""
                                placeholder=""
                                aria-controls="example"
                                onChange={(e) => {
                                  setSearchTempKeyword(e.target.value);
                                  if (e.target.value === "") {
                                    setListLoader(true);
                                    setSearchKeyword("");
                                  }
                                }}
                                // onKeyDown={(e) => {
                                //   if (e.key === "Enter") {
                                //     setSearchKeyword(e.target.value);
                                //   }
                                // }}
                              />
                            </label>

                            <div
                              style={{
                                border: "1px solid #aaa",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "9px",
                                marginBottom: "7px",
                                cursor: "pointer",
                                marginTop: "-1px",
                                marginLeft: "-1px",
                              }}
                              onClick={() => {
                                if (searchKeyword !== searchTempKeyword) {
                                  flushSync(() => {
                                    setSearchActive(true);
                                    setSortKey("");
                                  });
                                  setListLoader(true);
                                  setSearchKeyword(searchTempKeyword);
                                }
                              }}
                            >
                              <i class="ph ph-magnifying-glass"></i>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="table-responsive">
                      <table className="table filter-table">
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th
                              onClick={() => {
                                handleSort("createdAt");
                              }}
                            >
                              Activity Date/Time
                              <span className="ms-1 bright">
                                {renderSortIcon("createdAt")}
                              </span>
                            </th>
                            <th
                              onClick={() => {
                                handleSort("employee_name");
                              }}
                            >
                              Employee Name
                              <span className="ms-1 bright">
                                {renderSortIcon("employee_name")}
                              </span>
                            </th>
                            <th>Application Name</th>
                            <th>Activity Type</th>
                            <th>Cost Center Name</th>
                            <th>Action</th>
                            {/* <th
                          onClick={() => {
                            setSortKey("employee_job_title");
                            setSortOrder(sortOrder === 1 ? -1 : 1);
                          }}
                        >
                          Job Title
                        </th> */}
                            {/* <th
                          onClick={() => {
                            setSortKey("employee_job_category");
                            setSortOrder(sortOrder === 1 ? -1 : 1);
                          }}
                        >
                          Job Category
                        </th> */}
                          </tr>
                        </thead>
                        {isInitialData && !loading_page && !listLoader ? (
                          <>
                            {customerData?.docs?.length > 0 ? (
                              <tbody>
                                {customerData.docs.map((Ele, i) => {
                                  const currentDate = formatDateComp(
                                    Ele.createdAt
                                  );
                                  const dateChanged =
                                    lastDate && currentDate !== lastDate;
                                  lastDate = currentDate;

                                  return (
                                    <React.Fragment key={Ele._id}>
                                      {dateChanged && i !== 0 && (
                                        <tr>
                                          <td
                                            colSpan="7"
                                            style={{
                                              backgroundColor: "#718093",
                                              height: "12px",
                                              width: "100%",
                                              padding: "0",
                                            }}
                                          ></td>
                                        </tr>
                                      )}
                                      <tr>
                                        <td>{i + pageCounter}</td>
                                        <td>{formatDate(Ele?.createdAt)}</td>
                                        <td>{`${
                                          Ele?.employee_id
                                            ? Ele.employee_id
                                            : "N/A"
                                        } - ${
                                          Ele?.users?.employee_name
                                            ? Ele.users.employee_name
                                            : "N/A"
                                        }`}</td>
                                        <td>{Ele?.applications?.title}</td>
                                        <td>{Ele?.activity_masters?.title}</td>
                                        <td>
                                          {
                                            Ele?.cost_center_masters
                                              ?.cost_center_name
                                          }
                                        </td>
                                        <td>
                                          <label className="edit">
                                            <LuEye
                                              size={20}
                                              onClick={() => {
                                                setShowModal({
                                                  state: true,
                                                  id: Ele._id,
                                                });
                                              }}
                                            />
                                          </label>
                                        </td>

                                        {/* <td className="statusTD">
                                    <Switch
                                      checked={Ele?.active_status}
                                      onChange={() => {
                                        handleSwitchChange(
                                          Ele?.active_status === 1 ? 0 : 1,
                                          Ele?._id
                                        );
                                      }}
                                      checkedChildren="Active"
                                      unCheckedChildren="Inactive"
                                    />
                                  </td> */}

                                        {/* <td>
                                    <label className="edit">
                                      <Link
                                        className="boxlink"
                                        to={`/admin/portal-user/edit-user/${Ele?._id}/${Ele?.employee_id}/${Ele?.customer_code}`}
                                      >
                                        <img src={Ico1} className="w-a" />
                                      </Link>
                                    </label>
                                  </td> */}
                                      </tr>
                                    </React.Fragment>
                                  );
                                })}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td colSpan="10">
                                    <div
                                      className="d-flex justify-content-center align-items-center"
                                      style={{ height: "200px" }}
                                    >
                                      <p class="text-secondary display-6">
                                        Oops! Data Not found
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </>
                        ) : (
                          <>
                            <tbody>
                              <tr>
                                <td colSpan="10">
                                  <div
                                    className="d-flex align-items-center justify-content-center w-100"
                                    style={{ height: "400px" }}
                                  >
                                    <span
                                      class="loader"
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                      }}
                                    ></span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </>
                        )}
                      </table>
                    </div>
                    {isInitialData && !loading_page && !listLoader ? (
                      <>
                        {!customerData?.docs?.length > 0 ? (
                          <div className="d-flex justify-content-between">
                            <div
                              class="dataTables_info"
                              id="example_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing 0 to 0 of total 0 entries
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-between">
                            <div
                              class="dataTables_info"
                              id="example_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing {pageCounter} to{" "}
                              {Math.min(
                                pageCounter + pageLimit - 1,
                                customerData.totalDocs
                              )}{" "}
                              of total {customerData?.totalDocs} entries
                            </div>
                            <div
                              class="dataTables_paginate paging_simple_numbers"
                              id="example_paginate"
                            >
                              <span>
                                <ReactPaginate
                                  previousLabel={"Previous"}
                                  nextLabel={"Next"}
                                  breakLabel={"..."}
                                  pageCount={customerData?.totalPages}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={5}
                                  onPageChange={handlePageClick}
                                  containerClassName={"pagination"}
                                  previousLinkClassName={"paginationLink"}
                                  nextLinkClassName={"paginationLink"}
                                  disabledClassName={"paginationDisabled"}
                                  activeClassName={"paginationActive"}
                                  forcePage={currentPage - 1}
                                />
                              </span>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DetailModal
        show={showModal.state}
        id={showModal.id}
        onHide={() => {
          setShowModal({ state: false, id: null });
        }}
      />
    </>
  );
}

export default UserActivity;
