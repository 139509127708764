import React, { useEffect, useState, useRef, useContext } from "react";
import "./index.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import { toast } from "react-toastify";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE, DOMAIN_URL } from "../../constants/compensate";
import Texterror from "../Texterror";
import { CategoryMappingValidation } from "../../utils/validator";
import { useSelector } from "react-redux";
import MultiSelectTwo from "../MultiSelectCustom/MultiSelectTwo";
import { ThemeContext } from "../../providers/ThemeProvider";

function EditFacilityForm() {
  const { ID } = useParams();
  const navigate = useNavigate();
  const customer_code = useSelector((state) => state.compensate.customerCode);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialData, setISInitialData] = useState(false);
  const [TimezoneOptions, setTimezoneOptions] = useState([]);
  const [theme, setTheme] = useContext(ThemeContext);
  const [initialValues, setInitialValues] = useState({
    customer_code: "",
    field_name: "",
    field_type: "",
    category_ids: "",
    notes: "",
  });
  const parentRef = useRef(null);
  const [costCenterList, setCostCenterList] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    const getInitalData = async () => {

      const getCustomerDetail = await getCustomerDetailList();


      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/IntervalFormField/view`,
        { _id: ID, customer_code: customer_code }
      );
      const Detail = resp?.data?.data?.field_details;
      setInitialValues((prev) => {
        return {
          ...prev,
          customer_code: Detail?.customer_code,
          category_ids: Detail?.category_ids,
          field_name: Detail?.field_name,
          field_type: Detail?.field_type,
          notes: Detail?.notes,
        };
      });

      const categoryIdsArray = Detail?.category_ids?.split(",").map(Number);
        const preselectedCategories = getCustomerDetail?.filter((category) =>
          categoryIdsArray.includes(category.value)
        );

        setSelectedCategories(preselectedCategories);
      setISInitialData(true);
    };
    const getTimezoneOptions = async () => {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/general/timezoneMaster`
      );

      setTimezoneOptions(resp.data.data);
    };
    getInitalData();
    getTimezoneOptions();
  }, [ID]);

  const onSubmit = (values, onSubmittingProps) => {
    console.log("form Values", values);
    formSubmit(values);
    // setTimeout(() => {
    //   onSubmittingProps.setSubmitting(false);
    // }, [2000]);
  };
  const formSubmit = async (values) => {

    const category_ids = selectedCategories
      .map((category) => category.value)
      .join(",");

    var formData = {
      _id: ID,
      field_name: values.field_name,
      customer_code: customer_code,
      field_type: values.field_type,
      category_ids: category_ids,
      notes: values.notes,
    };
    try {
      setIsLoading(true);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/IntervalFormField/create`,
        formData
      );
      console.log(resp);
      toast.success(resp?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      navigate("/admin/category-mapping");
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });

      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };


      const getCustomerDetailList = async () => {
        try {
          const resp = await axios.post(
            `${API_CALL_URL_PRIVATE}/costcentercategory/distinctList`,
            {
              customer_code: parseInt(customer_code),
            }
          );

          const Data = resp?.data?.data?.data;

          const drop_facility = Data?.map((e) => {
            return {
              label: e?.cost_center_category,
              value: e?.cost_center_category_id,
            };
          });
          setCostCenterList([...drop_facility]);
          return drop_facility;
        } catch (error) {
          console.log(error);
        }
      };

     const handleCheckboxDropChange = (selected) => {
      console.log("selected", selected);
       setSelectedCategories(selected);
     };
     

     console.log("cosCe", costCenterList);

  return (
    <>
      {isInitialData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={CategoryMappingValidation}
          enableReinitialize
          validateOnChange
        >
          {(formik) => (
            <div className="main-panel">
              {console.log(formik)}
              <div className="content-wrapper">
                <div className="page-header">
                  <h3 className="page-title">
                    <a href="#" className="badge badge-dark"></a>
                  </h3>
                  <div className></div>
                </div>
                <div className="row">
                  <div class="col-12 grid-margin stretch-card">
                    <div class="card">
                      <div class="card-body">
                        <div className="d-flex justify-content-between">
                          <h4 class="card-title">Edit Category Mapping</h4>
                          <Link
                            to={`/admin/category-mapping`}
                            className="f-14 white-text-dark-mode"
                          >
                            <FontAwesomeIcon icon="fa-solid fa-arrow-left " />{" "}
                            Back
                          </Link>
                        </div>

                        <FormikForm className="forms-sample">
                          <div class="row">
                            <Form.Group
                              className="col-md-4 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <Form.Label>
                                Field Name
                                <span style={{ color: "red" }}>
                                  &nbsp;*
                                </span>{" "}
                                (Accept small letters, numbers, underscores)
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="field_name"
                                {...formik.getFieldProps("field_name")}
                              />
                              <ErrorMessage
                                name="field_name"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-4 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>
                                Field Type
                                <span style={{ color: "red" }}>
                                  &nbsp;*
                                </span>{" "}
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="field_type"
                                value={`${formik.values.field_type}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "field_type",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "field_type",
                                    e.target.value
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "field_type",
                                    true,
                                    true
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="0">Select</option>
                                <option value="patient_data">
                                  Patient Data
                                </option>
                                <option value="staffing">Staffing</option>
                                <option value="additional_data_tracking">
                                  Additional Data Tracking
                                </option>
                              </Form.Select>
                              <ErrorMessage
                                name="field_type"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-4 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Cost Center Category</Form.Label>
                              <div
                                style={{
                                  minWidth: "150px",
                                }}
                              >
                                <MultiSelectTwo
                                  placeholder="Cost Center Category"
                                  parentRef={parentRef}
                                  options={costCenterList}
                                  onChange={(e) => {
                                    handleCheckboxDropChange(e);
                                  }}
                                  style={{ paddingBottom: "17px" }}
                                  value={selectedCategories}
                                  isSelectAll={true}
                                  menuPlacement={"bottom"}
                                  theme={theme.theme === "LIGHT"}
                                  closeMenuOnSelect={false}
                                />
                              </div>
                            </Form.Group>

                            <Form.Group
                              className="col-md-12 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <Form.Label>Note</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder=""
                                name="notes"
                                {...formik.getFieldProps("notes")}
                              />
                            </Form.Group>

                            <Form.Group className="col-md-12 mb-3 d-flex justify-content-end">
                              {/* <Button
                              variant="primary"
                              type="button"
                              onClick={() => {
                                formik.resetForm();
                                setSelectedImage(null);
                                // const fileInput =
                                //   document.getElementById("logo");
                                // if (fileInput) {
                                //   fileInput.value = "";
                                // }
                              }}
                            >
                              Reset
                            </Button> */}
                              <Button
                                className="ms-3"
                                variant="success"
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading ? "Saving..." : "Save"}
                              </Button>
                            </Form.Group>
                          </div>
                        </FormikForm>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      ) : (
        <div className="d-flex align-items-center justify-content-center w-100vw h-100vh">
          <span class="loader" style={{ width: "50px", height: "50px" }}></span>
        </div>
      )}
    </>
  );
}

export default EditFacilityForm;
